import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactQuill from "react-quill";
import api from "../../api";
import Logo from "../../images/logo-2.png";
import add from "../../images/add.png";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";

function AddPost({ match }) {
  const adminStore = useSelector((state) => state.adminReducer);

  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [inputData, setInputData] = useState({
    description: "",
    img: "",
    format: "",
  });

  const handleSubmit = async () => {
    try {
      if (inputData.description) {
        setLoader(true);
        let payload = {
          _id: adminStore.admin.admin._id,
          description: inputData.description,
          video: inputData?.video || "",
          pdfFile: inputData?.pdfFile || "",
          imageTitle: inputData.img || "",
          imageFormat: inputData.format || "",
        };
        // console.log(payload);
        const signalResponse = await api.postCreate(payload);
        console.log(signalResponse);

        if (signalResponse?.success) {
          alert("Post created successfully");
          setInputData({
            description: "",
            img: "",
            format: "",
            video: "",
          });
          setLoader(false);
          setSelectedImg(null);
          setSelectedPDF(null);
          // sendNotification()
        } else {
          setLoader(false);
          alert("Something went wrong");
        }
      } else {
        setLoader(false);
        alert("Please insert description");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      alert("Something went wrong");
    }
  };

  const sendNotification = async () => {
    try {
      const signalResponse = await api.sendPostMailToAllUser();
      if (signalResponse?.success) {
        alert("email sent to all users");
      }
    } catch (error) {
      alert("Something went wrong");
    }
  };

  var Editor = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const onFileChange = async (e) => {
    try {
      setLoader(true);

      const formData = new FormData();
      formData.append("image", e.target.files[0], e.target.files[0].name);
      const uploadRes = await api.uploadFile(formData);
      console.log(uploadRes);
      setSelectedImg(
        `${process.env.REACT_APP_S3URL}/img_${uploadRes[0]}.${uploadRes[1]}`
      );
      setInputData({
        ...inputData,
        img: uploadRes[0],
        format: uploadRes[1],
      });
      setLoader(false);
    } catch (e) {
      alert("File upload failed");
      setLoader(false);
      console.log(e.message);
    }
  };
  const onPDFChange = async (e) => {
    try {
      setLoader(true);

      const formData = new FormData();
      formData.append("pdf", e.target.files[0], e.target.files[0].name);
      const uploadRes = await api.uploadPDF(formData);
      console.log(uploadRes);
      setSelectedPDF(`${process.env.REACT_APP_S3URL}/${uploadRes}`);
      setInputData({
        ...inputData,
        pdfFile: `${uploadRes}`,
      });
      setLoader(false);
    } catch (e) {
      alert("File upload failed");
      setLoader(false);
      console.log(e.message);
    }
  };

  const handleChange = (e) => {
    // console.log(e);
    setInputData({ ...inputData, description: e });
  };

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col">
            <div className="row mx-0 drivers-row">
              <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
                  Add Signal
                </h4>
              </div>
            </div>
            <div className="card sortby-box br-10 border-transparent mt-3">
              <div className="card-header br-10  bg-white border-transparent py-3 ">
                {/* <div className="form-group mb-3">
                                <input type="text" className='form-control' onChange={(e)=>setTitle(e.target.value)} placeholder='Title...' />
                              </div> */}
                <div className="descMainPost">
                  <ReactQuill
                    placeholder="Add description"
                    modules={Editor}
                    theme="snow"
                    value={inputData.description}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group my-3">
                  <textarea
                    name="video"
                    className="form-control"
                    value={inputData.video}
                    onChange={(e) =>
                      setInputData({ ...inputData, video: e.target.value })
                    }
                    placeholder="Add Video Iframe"
                  ></textarea>
                </div>
                <div className="form-group mb-3 fileUpload-modal">
                  <label>
                    <input
                      name="file"
                      onChange={onFileChange}
                      accept="audio/*,video/*,image/*"
                      type={"file"}
                    />
                    {selectedImg == null && <img src={add} />}
                    {selectedImg && (
                      <img className="img_sel" src={selectedImg} />
                    )}
                  </label>
                </div>
                <div>Upload PDF</div>
                <div className="form-group mt-0 mb-3 fileUpload-modal">
                  <label>
                    <input
                      name="file"
                      onChange={onPDFChange}
                      accept="application/pdf"
                      type={"file"}
                    />
                    {selectedPDF == null && <img src={add} />}
                    {selectedPDF && (
                      <a href={selectedPDF} download={inputData?.pdfFile}>
                        File Uploaded
                      </a>
                    )}
                  </label>
                </div>
                <div className="sendMailBtn mt-3">
                  <button onClick={handleSubmit} className="btn-dark">
                    Submit
                  </button>
                </div>
              </div>
            </div>
            {/* table */}
          </div>
        </div>
      </div>
      {/* /.container-fluid */}
      {loader && (
        <div className="seekoLoader">
          <div className="loader">
            <img src={Logo} alt="loader" />
          </div>
        </div>
      )}
    </>
  );
}

export default AddPost;
