import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api";
import { useAlert } from "react-alert";
import { Loader } from "../global/loader";
import userAction from "../../redux/users/action";

export const TelegramModal = ({ show, handleClose }) => {
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();
  const dispatch = useDispatch();
  const { setUser } = userAction;

  const [loader, setLoader] = useState(false);
  const [telegram, setTelegram] = useState("");

  const handleSubmit = async () => {
    try {
      if (!telegram) {
        alert.show("Please insert Telegram id");
        return;
      }
      if (telegram) {
        setLoader(true);
        let payload = {
          _id: userStore?.users?.user?._id,
          telegram: telegram,
        };

        // console.log(payload);
        const signalResponse = await api.addTelegram(payload);
        if (signalResponse?.success) {
          let updatedData = userStore?.users;
          updatedData.user.telegram = payload.telegram;
          dispatch(setUser(updatedData));
          alert.show("Telegram added successfully");
          setTelegram("");
          setLoader(false);
          handleClose();
          // sendNotification()
        } else {
          setLoader(false);
          alert.show("Something went wrong");
        }
      } else {
        setLoader(false);
        alert.show("Please insert telegram Id");
      }
    } catch (error) {
      setLoader(false);
      handleClose();
      console.log(error);
      alert.show("Something went wrong");
    }
  };
  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} centered className="">
        <Modal.Body>
          <div className="otpModalMain">
            <div className="closeIcon" onClick={handleClose}>
              <i className="fa-solid fa-xmark" />
            </div>

            <div className="row">
              {/* Area Chart */}
              <div className="col">
                <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
                  Please Add Your Telegram Username
                </h4>

                <div className="card sortby-box br-10 border-transparent mt-3">
                  <div className="card-header br-10  bg-white border-transparent py-3 ">
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={telegram}
                        onChange={(e) => setTelegram(e.target.value)}
                        placeholder="Your Telegram Username..."
                      />
                    </div>

                    <div className="sendMailBtn mt-3 text-center">
                      <button onClick={handleSubmit} className="btn-dark">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                {/* table */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {loader && <Loader />}
    </>
  );
};
