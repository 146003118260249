import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactQuill from "react-quill";
import api from "../../api";
import Logo from "../../images/logo-2.png";
import add from "../../images/add.png";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";

export function EditVideoLink({ getVideo, setShow, videoData }) {
  const adminStore = useSelector((state) => state.adminReducer);
  const [loader, setLoader] = useState(false);
  const [inputData, setInputData] = useState({
    video: "",
    category: "",
  });
  const [isRecording, setIsRecording] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!inputData?.category) {
        setLoader(false);
        alert("Please select category");
        return;
      }
      setLoader(true);
      const formData = {
        _id: videoData?._id,
        video: inputData?.video,
        title: inputData?.title || "",
        category: inputData?.category,
      };

      const signalResponse = await api.videoedit(formData);
      console.log(signalResponse);

      if (signalResponse?.success) {
        alert("Video edit successfully");
        setInputData({
          title: "",
          video: "",
          category: "",
        });

        setLoader(false);
        setShow(false);
        getVideo();
      } else {
        setLoader(false);
        alert("Something went wrong");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      alert("Something went wrong");
    }
  };

  const handleChange = (e) => {
    // console.log(e);
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setInputData({
      title: videoData?.title || "",
      video: videoData?.video || "",
      category: videoData?.category || "",
    });
  }, []);

  console.log(inputData);

  return (
    <>
      <div className="row mx-0 drivers-row">
        <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
          <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
            Edit Video
          </h4>
        </div>
      </div>
      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="descMainPost">
            <div className="form-group mb-3">
              <input
                type="text"
                name="title"
                className="form-control"
                onChange={handleChange}
                value={inputData?.title}
                placeholder="Add Title"
              />
            </div>

            <div className="form-group mb-3">
              <textarea
                name="video"
                id=""
                className="form-control"
                onChange={handleChange}
                value={inputData?.video}
                placeholder="Add Video Iframe"
              ></textarea>
            </div>
          </div>
          <div className="form-group mb-3">
            <select
              name="category"
              onChange={handleChange}
              value={inputData?.category}
              className="form-control"
            >
              <option value="">Select Category</option>
              <option value="beginner">Beginner</option>
              <option value="advance">Advance</option>
            </select>
          </div>

          <div className="sendMailBtn mt-3">
            <button
              onClick={handleSubmit}
              className="btn-dark"
              disabled={isRecording}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      {loader && (
        <div className="seekoLoader">
          <div className="loader">
            <img src={Logo} alt="loader" />
          </div>
        </div>
      )}
    </>
  );
}
